import { Empresa } from "./empresa";
import { UsuarioSucursal } from "./usuario-sucursal";
export class Usuario {
  id: number;
  nombre: string;
  apellidoPaterno: string;
  apellidoMaterno: string;
  correo: string;
  password: string;
  estatus: number;
  dashboard: boolean;
  empresas: boolean;
  bancos: boolean;
  facturas: boolean;
  nominas: boolean;
  ingresos: boolean;
  egresos: boolean;
  reportes: boolean;
  empresa: Empresa;
  fechaCreacion: number;
  fechaRegistro: number;
  isMoral: boolean;
  fechaModificacion: number;
  nombreEmpresa: string;
  usuarioSucursalList: Array<UsuarioSucursal>;
  idsSucursalList: Array<Number>;
  ver: boolean;
}
export class Recuperacion {
  email: string;
  empresaId: number;
}
